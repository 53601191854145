import React, { PropsWithChildren, useEffect } from "react"
import { gql } from "@apollo/client"
import { PopUpModalFragment } from './__generated__/PopUpModal'
import {
  StyledH2,
  StyledModalContentWrapper,
  StyledPopUpContent,
  StyledButton
} from "./StyledPopUpModal";

import {
  StyledModal,
  StyledIconButton,
  StyledIcon
} from "../GlobalAlertModal/StyledGlobalAlertModal";

import { ButtonProps } from '../button/button'
import { faClose  } from '@fortawesome/pro-regular-svg-icons'

type Maybe<T> = T | null | undefined

export type PopUpModalProps = PopUpModalFragment & {
  title: string
  popUpStatus?: boolean | Maybe<string>[]
  content?: any
  btnText?: string
}

export const PopUpModal: React.FC<PropsWithChildren<PopUpModalProps>> = ({title, popUpStatus, content, btnText}) => {
  const [open, setOpen] = React.useState(true);

  const handleClose = (event: React.MouseEvent<HTMLButtonElement>, reason: string) => {
    if (reason !== "backdropClick" && reason !== "escapeKeyDown") {
      setOpen(false);
    }
  };

  const handleButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    handleClose(event, 'buttonClick');
  };

  useEffect(() => {
    if (!popUpStatus) {
      setOpen(false);
    }
  }, [popUpStatus]);

  const ButtonLink = React.forwardRef<HTMLButtonElement, ButtonProps>((props, ref) => (
    <StyledButton {...props} innerRef={ref}>
      {props.children}
    </StyledButton>),
  )

  return (
    <div>
      <StyledModal
        open={open}
        onClose={handleClose}
        aria-labelledby={`Modal ${title}`}
        aria-describedby={`Modal ${title}`}
      >
        <StyledModalContentWrapper>
          <StyledIconButton onClick={handleButtonClick}>
            <StyledIcon icon={faClose} />
          </StyledIconButton>
          <StyledH2>{title}</StyledH2>
          <StyledPopUpContent dangerouslySetInnerHTML={{__html: content}} />
          <ButtonLink primary buttonColor="orange" onClick={handleButtonClick}>
            {btnText}
          </ButtonLink>
        </StyledModalContentWrapper>
      </StyledModal>
    </div>
  )
}

export const POP_UP_MODAL_FRAGMENT = gql`
    fragment PopUpModalFragment on popUpModal_modalContent_BlockType {
        title: popUpTitle
        popUpStatus: popUpStatus
        content: popUpContent
        btnText: closeButtonText
    } 
`