import { gql } from '@apollo/client'
import React, { PropsWithChildren } from 'react'
import { Breadcrumb, Breadcrumbs } from '../../components/breadcrumbs/Breadcrumbs'
import { IMAGE_FALLBACK_FRAGMENT } from '../../fragments/image'
import { GLOBAL_LINKS_CTA_FRAGMENT } from '../../fragments/linksCtaFallback'
import { SECONDARY_PAGE_BANNER_FRAGMENT, SecondaryPageBanner } from '../../fragments/secondaryPageBanner'
import { nonNull } from '../../utils/nonNull'
import { PRODUCT_DETAILS_QUERY } from '../productDetails'
import { PRODUCT_LISTING_QUERY } from '../productListing'
import { PageQuery } from './__generated__'
import { FLEXIBLE_CONTENT_FRAGMENT, FlexibleContent } from './fragments/flexibleContent'
import {POP_UP_MODAL_FRAGMENT, PopUpModal} from '../../components/popUpModal/PopUpModal'

export type PageProps = PageQuery

export const Page: React.FC<PropsWithChildren<PageProps>> = ({ entry, globalSet }) => {
  if (!entry || entry.__typename !== 'products_pages_product_fcp_Entry' || !entry?.secondaryPageBanner?.[0]?.__typename && entry?.secondaryPageBanner?.[0]?.__typename !== 'secondaryPageBanner_BlockType') {
    return null
  }
  
  const { image, title, primaryButton, secondaryButton, subTitle } = entry?.secondaryPageBanner?.[0]

  const bannerImage = image?.length === 0 && globalSet?.__typename === 'siteSettings_GlobalSet' ? globalSet?.flexibleContentImageFallback : image

  const breadcrumbs = (
    <Breadcrumbs>
      <Breadcrumb href={'/'} home text={'Home'} />
      {entry.parent?.parent && (
        <Breadcrumb href={`/${entry.parent.parent.uri}`} text={entry.parent.parent.title || ''} />
      )}
      {entry.parent && (
        <Breadcrumb href={`/${entry.parent.uri}`} text={entry.parent.title || ''} />
      )}
      <Breadcrumb href={`/${entry.uri}`} text={entry.title || ''} />
    </Breadcrumbs>
  )

  const popUpData = entry?.popUpModal?.[0]

  return (
    <div>
      {popUpData?.popUpStatus && popUpData?.title && (
        <PopUpModal
          title={popUpData.title}
          popUpStatus={popUpData?.popUpStatus}
          content={popUpData?.content}
          btnText={popUpData?.btnText ? popUpData?.btnText : 'Close'}
        />
      )}

      {entry?.secondaryPageBanner?.[0] && (
        <SecondaryPageBanner breadcrumbs={breadcrumbs} after={'white'} title={title} subTitle={subTitle}
                             primaryButton={primaryButton} secondaryButton={secondaryButton} image={bannerImage}/>
      )}

      {entry.flexibleContent && (
        <FlexibleContent entryId={entry.id as string} content={nonNull(entry.flexibleContent)}/>
      )}
    </div>
  )
}

export const PAGE_QUERY = gql`
  ${FLEXIBLE_CONTENT_FRAGMENT}
  ${PRODUCT_LISTING_QUERY}
  ${SECONDARY_PAGE_BANNER_FRAGMENT}
  ${PRODUCT_DETAILS_QUERY}
  ${GLOBAL_LINKS_CTA_FRAGMENT}
  ${IMAGE_FALLBACK_FRAGMENT}
  ${POP_UP_MODAL_FRAGMENT}

  query PageQuery($uri: [String], $uid: [String]) {
    entry(uri: $uri, uid: $uid) {
      id
      uri
      title
      parent {
        uid
        uri
        title
        
        parent {
          uid
          uri
          title
        }
      }
      ... on products_pages_product_fcp_Entry {
        id
        secondaryPageBanner {
          ...SecondaryPageBannerFragment
        }
        flexibleContent{
          ...FlexibleContentFragment
        }
        popUpModal {
          ...PopUpModalFragment
        }
      }
      ...ProductListingFragment
      ...ProductDetailsFragment
    }
    linksCtaFallback: globalSet(handle: "ctasProductPages") {
      ...globalLinksCtaFragment
    }
    globalSet  {
      ...ImageFallbackFragment
    }
  }
`