import { gql } from '@apollo/client'

export const GLOBAL_LINKS_CTA_FRAGMENT = gql`
  fragment globalLinksCtaFragment on ctasProductPages_GlobalSet {
      globalLinksCta: linksCta {
          ... on linksCta_BlockType {
            linksCtaTitle
            linksCtaSubtitle
            linksCta1Icon
            linksCTALink1 {
            url
            customText
            target
            }
            linksCta1Subtext
            linksCta2Icon
            linksCTALink2 {
            url
            customText
            target
            }
            linksCta2Subtext
            linksCta3Icon
            linksCTALink3 {
            url
            customText
            target
            }
            linksCta3Subtext
            linksCta4Icon
            linksCTALink4 {
            url
            customText
            target
            }
            linksCta4Subtext
        }
        }
    }
`