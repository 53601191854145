import styled from 'styled-components'
import { Box, Modal } from "@material-ui/core";
import { Button } from "../button/button"
import { Icon } from '../icon/icon'

export const StyledModalContentWrapper = styled(Box)`
    background: url('/images/popup-bg.png') no-repeat bottom right #fff;
    background-size: ${props => props.theme.typography.pxToRem(50)};;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: ${props => props.theme.typography.pxToRem(880)};
    width: calc(100% - ${props => props.theme.typography.pxToRem(70)});
    padding: ${props => props.theme.typography.pxToRem(50)} ${props => props.theme.typography.pxToRem(25)};
    max-height: calc(100% - ${props => props.theme.typography.pxToRem(70)});
    overflow-y: auto;
    border: none;
    outline: none;
    min-width: ${props => props.theme.typography.pxToRem(320)};

    ${props => props.theme.breakpoints.up('sm')} {
        padding: ${props => props.theme.typography.pxToRem(80)} ${props => props.theme.typography.pxToRem(75)};
        background-size: ${props => props.theme.typography.pxToRem(75)};;
    }

    ${props => props.theme.breakpoints.up('md')} {
        padding: ${props => props.theme.typography.pxToRem(90)} ${props => props.theme.typography.pxToRem(105)};
        background-size: ${props => props.theme.typography.pxToRem(75)};;
    }
`

export const StyledH2 = styled.h2`
  font-size: ${props => props.theme.typography.pxToRem(20)};
  line-height: ${props => props.theme.typography.pxToRem(30)};
  font-weight: 500;

  ${props => props.theme.breakpoints.up('sm')} {
    font-size: ${props => props.theme.typography.pxToRem(32)};
    line-height: ${props => props.theme.typography.pxToRem(45)};
    margin-top: 0;
  }
`

export const StyledPopUpContent = styled.div`
    font-size: ${props => props.theme.typography.pxToRem(15)};
    line-height: ${props => props.theme.typography.pxToRem(25)};

    ${props => props.theme.breakpoints.up('sm')} {
        font-size: ${props => props.theme.typography.pxToRem(17)};
        line-height: ${props => props.theme.typography.pxToRem(35)};
    }
    
    p {
        margin-bottom: ${props => props.theme.typography.pxToRem(25)};
        font-size: ${props => props.theme.typography.pxToRem(15)};

        ${props => props.theme.breakpoints.up('sm')} {
            margin-bottom: ${props => props.theme.typography.pxToRem(15)};
            font-size: ${props => props.theme.typography.pxToRem(17)};
        }
        
        strong {
            font-weight: 500;
        }
    }
    
    ul, ol {
        margin-bottom: ${props => props.theme.typography.pxToRem(25)};
        
        li {
            font-size: ${props => props.theme.typography.pxToRem(15)};
            line-height: ${props => props.theme.typography.pxToRem(20)};
            margin-bottom: ${props => props.theme.typography.pxToRem(10)};

            ${props => props.theme.breakpoints.up('sm')} {
                font-size: ${props => props.theme.typography.pxToRem(17)};
                line-height: ${props => props.theme.typography.pxToRem(35)};
                margin-bottom: 0;
                margin-left: -${props => props.theme.typography.pxToRem(15)};

                &::before {
                    left: -${props => props.theme.typography.pxToRem(25)};
                }
            }
        }
    }
    
    ul {
        li {
            &::before {
                top: ${props => props.theme.typography.pxToRem(2)};

                ${props => props.theme.breakpoints.up('sm')} {
                    top: ${props => props.theme.typography.pxToRem(11)};
                    left: -${props => props.theme.typography.pxToRem(25)} !important;
                }

                ${props => props.theme.breakpoints.up('md')} {
                    top: ${props => props.theme.typography.pxToRem(10)};
                }
            }
        }
    }
    
    ol {
        li {
            &:before {
                ${props => props.theme.breakpoints.up('sm')} {
                    left: -${props => props.theme.typography.pxToRem(25)} !important;
                }
            }
        }
    }
`

export const StyledButton = styled(Button)`
    color: #ed8b00;
    border-color:  #ed8b00;

    ${props => props.theme.breakpoints.up('sm')} {
        margin-top: ${props => props.theme.typography.pxToRem(25)};
    }
    
    &:hover {
        color: #d83b00;
    }
`
